import React, { useEffect } from "react"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import ButtonLink from "../components/buttonLink"

const Hero = styled.div`
  min-height: 100%;
`
const StyledH1 = styled.h1``

const ContactSection = styled.section`
  position: relative;
  z-index: 1;
  margin-bottom: 15%;
  @media (min-width: 768px) {
    margin-bottom: 10%;
  }
`

const StyledButtonLink = styled(ButtonLink)`
  justify-content: flex-start !important;
  margin-top: 20px;
`

export default function Page({
  data, // this prop will be injected by the GraphQL query below.
}) {
  useEffect(() => {
    window.location.replace("https://chunk.homerun.co")
  }, [])

  return (
    <>
      <SEO
        title="We're hiring"
        description="Working for international clients?"
      />

      <section className="section-header position-relative">
        <Hero>
          <div className="container-fluid d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-12 text-center">
                <StyledH1 className="display-2 font-weight-bolder mb-1 mb-md-2 mb-lg-4">
                  <span>We're hiring!</span>
                </StyledH1>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-7 text-center">
                <p></p>
              </div>
            </div>
          </div>
        </Hero>
      </section>

      <ContactSection className="">
        <div className="container-fluid">
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-lg-6">
              <h5 className="h5 text-uppercase mt-3 mt-lg-0 mb-1 mb-lg-3 font-weight-bolder">
                User Interface Designer
              </h5>
              <p className="font-weight-light">
                We are looking for a creative digital designer who can translate
                brand values and brand look & feel into impressive online
                experiences for our clients.
              </p>
              <StyledButtonLink
                title="Read more"
                to="https://chunk.homerun.co/user-interface-designer"
                target="_blank"
                rel="noreferrer"
              />
            </div>
            <div className="col-12 col-lg-6">
              <h5 className="h5 text-uppercase mt-5 mt-lg-0 mb-1 mb-lg-3 font-weight-bolder">
                User Experience Designer
              </h5>
              <p className="font-weight-light">
                We are looking for an innovative digital UX designer who can
                translate insights and knowledge about human behavior into
                powerful online experiences for our clients.{" "}
              </p>
              <StyledButtonLink
                title="Read more"
                to="https://chunk.homerun.co/user-experience-designer"
                target="_blank"
                rel="noreferrer"
              />
            </div>
          </div>
        </div>
      </ContactSection>
    </>
  )
}
