// Icon
import React from "react"
import { Link } from "gatsby"
import ArrowRight from "./arrowRight"

function ButtonLink(props) {
  const { title, to, className, ...otherProps } = props

  return (
    <Link
      className={`d-flex align-items-center flex-nowrap black link--line-under-span ${className}`}
      to={to}
      {...otherProps}
    >
      <span className="h6 font-weight-bolder black">{title}</span>
      <span className="h6 circle black">
        <ArrowRight />
      </span>
    </Link>
  )
}

export default ButtonLink
